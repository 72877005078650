import React, { useEffect } from 'react';
import _ from 'lodash';
import Modal from '../Modal';
import styled from 'styled-components';
import * as GoogleAnalytics from '../../lib/google-analytics';
import { roomNoFormatter } from '../../lib/formatter';

interface CheckInConfirmProps {
  isOpen: boolean,
  selectedRoom: any,
  onClose: () => void,
  checkIn: () => void,
}

const ConfirmWrap = styled.div`
.check-in-confirm-title {
  display: block;
  height: 24px;
  margin: 14px 0 8px;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.4px;
  text-align: center;
  color: #000;
}
.check-in-confirm-description {
  display: block;
  height: 20px;
  margin: 0 0 20px;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -0.4px;
  text-align: center;
  color: #666;
}
.room-info-container {
  padding: 16px 25px 16px;
  margin: 0 0 8px;
  border-radius: 8px;
  background-color: #f9f9f9;
}
  .room-info-container .room-info-description {
    display: block;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
    letter-spacing: -0.2px;
    text-align: center;
    color: #666;
  }
.check-in-confirm-button-container {
  display: flex;
}
  .check-in-confirm-button-container .cancel-button,
  .check-in-confirm-button-container .check-in-request-button {
    width: 100%;
    height: 42px;
  }
    .check-in-confirm-button-container .cancel-button .cancel-button-text,
    .check-in-confirm-button-container .check-in-request-button .check-in-request-button-text {
      display: block;
      height: 42px;
      font-size: 14px;
      font-stretch: normal;
      font-style: normal;
      line-height: 42px;
      letter-spacing: -0.4px;
      color: #b6b6b6;
      text-align: center;
    }
`;

const CheckInConfirm = ({
  isOpen,
  selectedRoom,
  onClose,
  checkIn,
}:CheckInConfirmProps) => {
  const debounceCheckIn = _.debounce(() => checkIn(), 800, { maxWait: 1000 });

  const onClickCheckIn = () => {
    GoogleAnalytics.customEvent({
      category: 'button_click',
      action: 'button_click_checkin_confirm_ok',
    });
    debounceCheckIn();
  };

  const onCancel = () => {
    GoogleAnalytics.customEvent({
      category: 'button_click',
      action: 'button_click_checkin_confirm_cancel',
    });
    onClose();
  };
  useEffect(() => {
    if (isOpen) GoogleAnalytics.customEvent({
      category: 'modal_view',
      action: 'modal_view_checkin_confirm',
    });
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      isClosable={false}
      onClose={onCancel}
      Content={(
        <ConfirmWrap>
          <span className='check-in-confirm-title'>
            체크인 하시겠습니까?
          </span>
          <span className='check-in-confirm-description'>
            선택하신 객실 정보를 확인해주세요.
          </span>
          <div className='room-info-container'>
            <span className='room-info-description'>
              {`${selectedRoom.zoneTypeName}`}<br/>
              {`${selectedRoom.floorName} ${roomNoFormatter(selectedRoom.roomNo)}`}
            </span>
          </div>
          <div className='check-in-confirm-button-container'>
            <div className='cancel-button' onClick={onCancel}>
              <span className='cancel-button-text'>
                아니요
              </span>
            </div>
            <div className='check-in-request-button' onClick={onClickCheckIn}>
              <span className='check-in-request-button-text c-black'>
                체크인 하기
              </span>
            </div>
          </div>
        </ConfirmWrap>
      )}
    />
  );
};

export default CheckInConfirm;