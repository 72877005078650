import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import MainLayout from "../components/layouts/MainLayout";
import CheckIn from "../components/views/CheckIn";
import { bookingListAction } from "../stores/actions";
import * as GoogleAnalytics from "../lib/google-analytics";
import { RootState } from "../stores/reducers";
import * as serverApi from "../api/server";
import Spinner from "../components/Spinner";
import ModalError from "../components/ModalError";

type Props = JSX.IntrinsicAttributes & { children?: React.ReactNode };

const CheckInContainer = (props: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModalError, setIsOpenModalError] = useState(false);
  const [modalErrorMessage, setModalErrorMessage] = useState("");
  const [modalErrorSubMessage, setModalErrorSubMessage] = useState("");

  const navigation = useNavigate();
  const { bookingItem, userInfo } = useSelector(
    (state: RootState) => state.bookingList
  );
  const isTestMode = process.env.REACT_APP_ENV === "production" ? false : true;

  const dispatch = useDispatch();
  const { Kakao } = window as any;

  const openModalError = () => {
    setIsOpenModalError(true);
  };

  const closeModalError = () => {
    setIsOpenModalError(false);
  };

  const getKakaoUserData = async () => {
    try {
      await serverApi.kakaoUserData({
        hotel_id: userInfo.hotel_id,
        account_email: userInfo.email,
        name: userInfo.name,
        gender: userInfo.gender,
        age_range: userInfo.age_range,
        birthday: userInfo.birthday,
        birthyear: userInfo.birthyear,
        phone_number: userInfo.phone_number,
      });
    } catch (error) {}
  };

  const handleCheckIn = async () => {
    try {
      setIsLoading(true);
      if (!bookingItem.rsvnNo) throw new Error("체크인을 할 수 없습니다.");
      const response = await serverApi.checkIn({
        rsvnNo: bookingItem.rsvnNo,
        rsvnSeqNo: "1",
        roomNo: bookingItem.roomNo,
      });
      if (response.code !== "0000") {
        setModalErrorMessage("체크인에 실패 하였습니다.");
        throw new Error(`${response.code}, ${response.message}`);
      }
      getKakaoUserData();
      navigation(`/?rsvnNo=${bookingItem.rsvnNo}`, { replace: true }); // 위 체크인 api 완료되면 원복
    } catch (error: any) {
      setModalErrorMessage(error.response?.data?.message);
      setModalErrorSubMessage(error.message);
      openModalError();
    } finally {
      setIsLoading(false);
    }
  };

  const loginWithKakao = useCallback(() => {
    // if (isTestMode) {
    //   handleCheckIn();
    //   return;
    // }
    try {
      Kakao.Auth.login({
        success: function (response: any) {
          getUserInfoWithKakao();
        },
        fail: function (error: any) {
          console.log(error);
        },
      });
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserInfoWithKakao = useCallback(async () => {
    try {
      Kakao.API.request({
        url: "/v2/user/me",
        success: function (response: any) {
          const parsedPhoneNumber = `0${response.kakao_account.phone_number
            .split(" ")[1]
            .replace(/-/gi, "")}`;
          dispatch(
            bookingListAction.setUserInfo({
              phoneNumber: parsedPhoneNumber,
              email: response.kakao_account.email,
              hotel_id: "3dbe03637a5f195c9e153bc9a41a0c89828da36f",
              account_email: response?.kakao_account?.email,
              name: response?.kakao_account?.name,
              gender: response?.kakao_account?.gender,
              age_range: response?.kakao_account?.age_range,
              birthday: response?.kakao_account?.birthday,
              birthyear: response?.kakao_account?.birthyear,
              phone_number: response?.kakao_account?.phone_number,
            })
          );
          // 엘라포니시만 결제 없음
          // navigation("/checkin/payment");
          handleCheckIn();
        },
        fail: function (error: any) {
          console.log(error);
        },
      });
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkIn = useCallback(async () => {
    try {
      loginWithKakao();
    } catch (error) {
      console.log(error);
    }
  }, [loginWithKakao]);

  useEffect(() => {
    if (bookingItem.rsvnStatusCode !== "RR")
      navigation(`/?rsvnNo=${bookingItem.rsvnNo}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingItem.rsvnNo, bookingItem.rsvnStatusCode]);

  useEffect(() => {
    GoogleAnalytics.customEvent({
      category: "page_view",
      action: "page_view_kakao_auth",
    });
  }, []);

  return (
    <div {...props}>
      <MainLayout ContentBody={<CheckIn checkIn={checkIn} />} />
      <ModalError
        isOpen={isOpenModalError}
        message={modalErrorMessage}
        subMessage={modalErrorSubMessage}
        onClose={closeModalError}
      />
      <Spinner isLoading={isLoading} />
    </div>
  );
};

export default CheckInContainer;
