import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import * as GoogleAnalytics from "../../lib/google-analytics";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import Modal from "../Modal";
import ModalError from "../ModalError";
import styled from "styled-components";

const LocationServiceWrap = styled.div`
  .location-service-title {
    display: block;
    height: 24px;
    margin: 14px 0 0;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.4px;
    text-align: center;
    color: #000;
  }
  .location-service-icon {
    font-size: 90px;
    color: #5f5f5f;
    text-align: center;
  }
  @media (max-height: 420px) {
    .location-service-icon {
      font-size: 100px;
    }
  }
  @media (max-height: 370px) {
    .location-service-icon {
      font-size: 80px;
    }
  }
  .location-service-description {
    display: block;
    margin: 0 0 20px;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.4px;
    text-align: center;
    word-break: keep-all;
    color: #666;
  }
  @media (max-width: 320px) {
    .location-service-description {
      font-size: 13px;
    }
  }

  button.confirm-button {
    width: 100%;
    height: 48px;
    padding: 0;
    background-color: #333;
    border: unset;
    border-radius: 8px;
    font-size: 15px;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.2px;
    color: #fff;
  }
  button.confirm-button:disabled {
    background-color: rgb(203, 204, 205);
  }
`;

const ModalLocationSerivce = ({ isOpen, bookingItem, onClose }) => {
  const navigation = useNavigate();
  const [isOpenModalError, setIsOpenModalError] = useState(false);
  const [modalErrorMessage, setModalErrorMessage] = useState("");
  const [modalErrorSubMessage, setModalErrorSubMessage] = useState("");

  const openModalError = useCallback(() => {
    setIsOpenModalError(true);
  }, []);

  const closeModalError = useCallback(() => {
    setIsOpenModalError(false);
  }, []);

  const verifyCurrentLocation = () => {
    return new Promise((resolve, reject) => {
      const hotelLat = 35.752542;
      const hotelLng = 129.492788;
      const centerRadiusOfHotel = 4000; // 엘라포니시 리조트 내부 중심으로 부터 반경: 4000m
      const options = {
        enableHighAccuracy: false,
        maximumAge: 5000,
        timeout: 3000,
      };
      if (window.navigator.geolocation) {
        function success(pos) {
          const crd = pos.coords;
          const currentLat = crd.latitude;
          const currentLng = crd.longitude;
          const currentDistance = getDistanceFromCurrentLocationToDestination(
            currentLat,
            currentLng,
            hotelLat,
            hotelLng
          ); //호텔 반경
          if (currentDistance - centerRadiusOfHotel < 0) {
            resolve();
          } else {
            reject({
              code: 0,
              message: "현재 사용자 위치는 엘라포니시 리조트 내부가 아닙니다.",
            });
          }
        }

        function error(error) {
          const formatErrorMessage = {
            0: "알 수 없는 에러 입니다.\n 새로고침 후 다시 시도해 주세요.",
            1: "위치 서비스가 꺼져 있습니다. 위치 서비스를 켜 주세요.\n 새로고침 후 다시 시도해 주세요.",
            2: "위치를 찾을 수 없습니다.\n 새로고침 후 다시 시도해 주세요.",
            3: "요청 응답 시간이 초과 되었습니다. 재요청 해주세요.",
          };
          reject({
            code: 0,
            message: formatErrorMessage[error.code] || error.message,
          });
        }
        navigator.geolocation.getCurrentPosition(success, error, options);
      } else {
        reject({
          code: 0,
          message: "이 브라우저에서는 Geolocation이 지원되지 않습니다.",
        });
      }
    });
  };

  function getDistanceFromCurrentLocationToDestination(
    currentLat,
    currentLng,
    destinationLat,
    destinationLng
  ) {
    var R = 6371; // Radius of the earth in km
    var dLat = toRadians(destinationLat - currentLat);
    var dLng = toRadians(destinationLng - currentLng);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRadians(currentLat)) *
        Math.cos(toRadians(destinationLat)) *
        Math.sin(dLng / 2) *
        Math.sin(dLng / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c * 1000; // Distance in m
    return d;
  }

  function toRadians(deg) {
    return deg * (Math.PI / 180);
  }

  const confirm = async () => {
    try {
      GoogleAnalytics.customEvent({
        category: "button_click",
        action: "button_click_location_service_request",
      });
      await verifyCurrentLocation();
      if (localStorage.getItem("bsnsCode") === "7888") navigation("/room");
      else navigation("/terms_of_use");
    } catch (error) {
      setModalErrorMessage("체크인을 할 수 없습니다.");
      setModalErrorSubMessage(error.message);
      openModalError();
    } finally {
      onClose();
    }
  };

  useEffect(() => {
    if (isOpen)
      GoogleAnalytics.customEvent({
        category: "modal_view",
        action: "modal_view_location_service",
      });
  }, [isOpen]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        isClosable={false}
        onClose={onClose}
        Content={
          <LocationServiceWrap>
            <span className="location-service-title">
              위치 정보 사용을 허용해 주세요
            </span>
            <div className="location-service-icon">
              <FontAwesomeIcon icon={faMapMarkerAlt} />
            </div>
            <span className="location-service-description">
              ※모바일 체크인은 엘라포니시 리조트 반경 4㎞
              <br />
              이내에서 서비스가 활성화 됩니다.
            </span>
            <button className="confirm-button h-40 mb-14" onClick={confirm}>
              확인
            </button>
          </LocationServiceWrap>
        }
      />
      <ModalError
        isOpen={isOpenModalError}
        message={modalErrorMessage}
        subMessage={modalErrorSubMessage}
        onClose={closeModalError}
      />
    </>
  );
};

export default ModalLocationSerivce;
