import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import moment from "moment";
import MainLayout from "../components/layouts/MainLayout";
import RoomSelect from "../components/views/RoomSelect";
import ModalError from "../components/ModalError";
import Spinner from "../components/Spinner";
import * as api from "../api/sanha";
import * as serverApi from "../api/server";
import { useNavigate, useSearchParams } from "react-router-dom";
import queryString from "query-string";

type Props = JSX.IntrinsicAttributes & { children?: React.ReactNode };

const RoomSelectContainer = (props: Props) => {
  const navigation = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const urlQueryStringParams: any = queryString.parse(searchParams.toString());
  const [isLoading, setIsLoading] = useState(false);
  const [totalRoomList, setTotalRoomList] = useState<
    api.listAvailableRoomServerApi[]
  >([]); //선택 가능한 객실 모든 정보
  const [roomList, setRoomList] = useState<api.listAvailableRoomServerApi[]>(
    []
  ); //선택 가능한 객실 목록
  const [buildingCodeList, setBuildingCodeList] = useState<any[]>([]); //선택 가능한 빌딩 코드 목록
  const [buildingNameList, setBuildingNameList] = useState<any[]>([]); //선택 가능한 빌딩 이름 목록
  const [floorCodeList, setFloorCodeList] = useState<any[]>([]); //선택 가능한 층 코드 목록
  const [floorNameList, setFloorNameList] = useState<any[]>([]); //선택 가능한 층 이름 목록
  const [selectedBuilding, setSelectedBuilding] = useState<string>(""); //선택한 빌딩
  const [selectedFloor, setSelectedFloor] = useState<string>(""); //선택한 층
  const [selectedRoom, setSelectedRoom] = useState<any>({}); //선택한 객실에 대한 모든 정보
  const [isOpenModalError, setIsOpenModalError] = useState(false);
  const [modalErrorMessage, setModalErrorMessage] = useState("");
  const [modalErrorSubMessage, setModalErrorSubMessage] = useState("");
  const { bookingItem, userInfo } = useSelector(
    (state: any) => state.bookingList
  );

  const isTestMode = process.env.REACT_APP_ENV === "production" ? false : true;

  const openModalError = () => {
    setIsOpenModalError(true);
  };

  const closeModalError = () => {
    setIsOpenModalError(false);
  };

  // const registCheckInPayment = useCallback(async () => {
  //   try {
  //     if (
  //       !urlQueryStringParams ||
  //       urlQueryStringParams.responseCode !== "0000"
  //     ) {
  //       setModalErrorMessage("결제 요청에 실패 하였습니다.");
  //       throw new Error("");
  //     } else {
  //       const response = await api.registCheckInPayment({
  //         rsvnNo: bookingItem.rsvnNo,
  //         rsvnSeqNo: "1",
  //         trnsNo: urlQueryStringParams.shopOrderNo, //거래 승인 번호
  //         intApprTypeCode: "P", //인터넷 결제 유형 코드
  //         intPayStatusCode: 11, //인터넷 결제 상태 코드
  //         payFnshDate: moment().format("YYYYMMDD"), //인터넷 지불 일자
  //         successYn: urlQueryStringParams.successYn,
  //         trnsDate: moment().format("YYYYMMDD"), //거래일자
  //         trnsHHMMSS: moment().format("HHmmss"),
  //         trnsAmt: urlQueryStringParams.trnsAmt,
  //         cardMonthCnt: urlQueryStringParams.cardMonthCnt || "", //할부 개월 수
  //         cardApprNo: urlQueryStringParams.cardApprNo || "", //카드 승인 번호
  //         cardName: urlQueryStringParams.cardName || "", //카드명
  //         cardIssueCode: urlQueryStringParams.cardIssueCode || "", //카드 발급사 코드
  //         cardPurcCode: urlQueryStringParams.cardPurcCode || "", //카드 매입사 코드
  //         trnsBankCode: "", //거래 은행 코드
  //         acctNo: "", //계좌번호
  //         depositor: urlQueryStringParams.depositor || "", //예금주명
  //         cashRcptTypeCode: 0, //현금 영수증 발급 구분 코드
  //         cashRcptApptNo: "", //현금 영수증 승인 번호
  //         cashRcptCxlApptNo: "", //현금 영수증 취소 승인 번호
  //         responseCode: urlQueryStringParams.responseCode || "",
  //         message1: urlQueryStringParams.responseMessage || "",
  //         message2: "",
  //         dpsitPayDivCode: "01",
  //         userId: "KEYLESS",
  //         userIp: "127.0.0.0",
  //         payMgtNo: "",
  //         dpsitPayNo: "",
  //         outYN: "",
  //         outMsg: "",
  //       });
  //       if (response.code !== "0000") {
  //         setModalErrorMessage(
  //           "결제는 성공하였으나, 이 후 처리 과정에서 문제가 생겼습니다."
  //         );
  //         throw new Error(`${response.code}, ${response.message}`);
  //       } else {
  //         if (bookingItem.roomNo) await checkIn();
  //         else await listAvailableRoom(); // 위 예약 결제 내역 등록 api 완료되면 원복
  //         navigation("/room");
  //       }
  //     }
  //   } catch (error: any) {
  //     setModalErrorMessage(error.response?.data?.message);
  //     setModalErrorSubMessage(error.message);
  //     openModalError();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const listAvailableRoom = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await api.listAvailableRoom({
        rsvnNo: bookingItem.rsvnNo,
        rsvnSeqNo: "1",
      });
      if (response.code !== "0000" && !response.data) {
        setModalErrorMessage("사용 가능 객실 조회에 실패 하였습니다.");
        throw new Error(`${response.code}, ${response.message}`);
      }
      if (response.data) {
        const floorCode = response.data.map((item) => item.floorCode);
        const floorName = response.data.map((item) => item.floorName);
        const buildingCode = response.data.map((item) => item.zoneTypeCode);
        const buildingName = response.data.map((item) => item.zoneTypeName);

        setBuildingCodeList(
          buildingCode.filter((code, i) => buildingCode.indexOf(code) === i)
        );
        setBuildingNameList(
          buildingName.filter((code, i) => buildingName.indexOf(code) === i)
        );
        setFloorCodeList(
          floorCode.filter((code, i) => floorCode.indexOf(code) === i)
        );
        setFloorNameList(
          floorName.filter((code, i) => floorName.indexOf(code) === i)
        );
        setTotalRoomList(response.data);
      }
    } catch (error: any) {
      setModalErrorMessage(error.response?.data?.message);
      setModalErrorSubMessage(error.message);
      openModalError();
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeBuilding = useCallback(
    (building: string) => {
      setSelectedBuilding(building);
      if (selectedFloor) {
        const roomList = totalRoomList.filter(
          (item) =>
            item.zoneTypeCode === building && item.floorCode === selectedFloor
        );
        setRoomList(roomList);
      }
    },
    [selectedFloor, totalRoomList]
  );

  const changeFloor = useCallback(
    (floor: string) => {
      setSelectedFloor(floor);
      if (selectedBuilding) {
        const roomList = totalRoomList.filter(
          (item) =>
            item.zoneTypeCode === selectedBuilding && item.floorCode === floor
        );
        setRoomList(roomList);
        setSelectedRoom({});
      }
    },
    [selectedBuilding, totalRoomList]
  );

  const changeRoom = useCallback(
    (room: api.listAvailableRoomServerApi) => {
      if (selectedRoom.roomNo === room.roomNo) return;
      setSelectedRoom(room);
    },
    [selectedRoom]
  );

  const getkakaoUserData = async () => {
    try {
      await serverApi.kakaoUserData({
        hotel_id: userInfo.hotel_id,
        account_email: userInfo.email,
        name: userInfo.name,
        gender: userInfo.gender,
        age_range: userInfo.age_range,
        birthday: userInfo.birthday,
        birthyear: userInfo.birthyear,
        phone_number: userInfo.phone_number,
      });
    } catch (error) {}
  };

  const checkInPayment = async () => {
    try {
      const checkInPaymentResponse = await api.config();

      if(checkInPaymentResponse.data.depositType === 'DEPOSIT') {
        const response = await api.checkInDeposit({
          rsvnNo: bookingItem.rsvnNo,
          rsvnSeqNo: "1",
          trnsNo: urlQueryStringParams.shopOrderNo, //거래 승인 번호
          intApprTypeCode: "P", //인터넷 결제 유형 코드
          intPayStatusCode: 11, //인터넷 결제 상태 코드
          payFnshDate: moment().format("YYYYMMDD"), //인터넷 지불 일자
          successYn: urlQueryStringParams.successYn,
          trnsDate: moment().format("YYYYMMDD"), //거래일자
          trnsHHMMSS: moment().format("HHmmss"),
          trnsAmt: urlQueryStringParams.trnsAmt,
          cardMonthCnt: urlQueryStringParams.cardMonthCnt || "", //할부 개월 수
          cardApprNo: urlQueryStringParams.cardApprNo || "", //카드 승인 번호
          cardName: urlQueryStringParams.cardName || "", //카드명
          cardIssueCode: urlQueryStringParams.cardIssueCode || "", //카드 발급사 코드
          cardPurcCode: urlQueryStringParams.cardPurcCode || "", //카드 매입사 코드
          trnsBankCode: "", //거래 은행 코드
          acctNo: "", //계좌번호
          depositor: urlQueryStringParams.depositor || "", //예금주명
          cashRcptTypeCode: 0, //현금 영수증 발급 구분 코드
          cashRcptApptNo: "", //현금 영수증 승인 번호
          cashRcptCxlApptNo: "", //현금 영수증 취소 승인 번호
          responseCode: urlQueryStringParams.responseCode || "",
          message1: urlQueryStringParams.responseMessage || "",
          message2: "",
          dpsitPayDivCode: "01",
          userId: "KEYLESS",
          userIp: "127.0.0.0",
          payMgtNo: "",
          dpsitPayNo: "",
          outYN: "",
          outMsg: "",
        });
      } else if(checkInPaymentResponse.data.depositType === 'PAYMENT') {
        const response = await api.checkInAdvancePayment({
          folioNo:userInfo.foliNo,
          trnsNo: urlQueryStringParams.shopOrderNo, //거래 승인 번호
          intApprTypeCode: "P", //인터넷 결제 유형 코드
          intPayStatusCode: 11, //인터넷 결제 상태 코드
          payFnshDate: moment().format("YYYYMMDD"), //인터넷 지불 일자
          successYn: urlQueryStringParams.successYn,
          trnsDate: moment().format("YYYYMMDD"), //거래일자
          trnsHHMMSS: moment().format("HHmmss"),
          trnsAmt: urlQueryStringParams.trnsAmt,
          cardMonthCnt: urlQueryStringParams.cardMonthCnt || "", //할부 개월 수
          cardApprNo: urlQueryStringParams.cardApprNo || "", //카드 승인 번호
          cardName: urlQueryStringParams.cardName || "", //카드명
          cardIssueCode: urlQueryStringParams.cardIssueCode || "", //카드 발급사 코드
          cardPurcCode: urlQueryStringParams.cardPurcCode || "", //카드 매입사 코드
          trnsBankCode: "", //거래 은행 코드
          acctNo: "", //계좌번호
          depositor: urlQueryStringParams.depositor || "", //예금주명
          cashRcptTypeCode: 0, //현금 영수증 발급 구분 코드
          cashRcptApptNo: "", //현금 영수증 승인 번호
          cashRcptCxlApptNo: "", //현금 영수증 취소 승인 번호
          responseCode: urlQueryStringParams.responseCode || "",
          message1: urlQueryStringParams.responseMessage || "",
          message2: "",
          userId: "KEYLESS",
          userIp: "127.0.0.0",
          payMgtNo: "",
          dpsitPayNo: "",
          outYN: "",
          outMsg: "",
        });
      }
      navigation(`/?rsvnNo=${bookingItem.rsvnNo}`); // 위 체크인 api 완료되면 원복
    } catch (error: any) {
      setModalErrorMessage(error.response?.data?.message);
      setModalErrorSubMessage(error.message);
      openModalError();
    }
    
  }

  const checkIn = async () => {
    try {
      setIsLoading(true);
      if (!bookingItem.rsvnNo) throw new Error("체크인을 할 수 없습니다.");
      const response = await serverApi.checkIn({
        rsvnNo: bookingItem.rsvnNo,
        rsvnSeqNo: "1",
        roomNo: bookingItem.roomNo || selectedRoom.roomNo,
      });
      if (response.code !== "0000") {
        setModalErrorMessage("체크인에 실패 하였습니다.");
        throw new Error(`${response.code}, ${response.message}`);
      }
      if (!isTestMode) getkakaoUserData();

      checkInPayment()
    } catch (error: any) {
      setModalErrorMessage(error.response?.data?.message);
      setModalErrorSubMessage(error.message);
      openModalError();
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (bookingItem.roomNo)
      checkIn(); //roomNo가 있을 경우 자동 체크인 배정.
    else listAvailableRoom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div {...props}>
      <MainLayout
        customStyle={{ position: "relative", margin: 0 }}
        ContentBody={
          <RoomSelect
            totalRoomList={totalRoomList}
            buildingCodeList={buildingCodeList}
            buildingNameList={buildingNameList}
            floorCodeList={floorCodeList}
            floorNameList={floorNameList}
            roomList={roomList}
            selectedRoom={selectedRoom}
            selectedBuilding={selectedBuilding}
            selectedFloor={selectedFloor}
            changeBuilding={changeBuilding}
            changeFloor={changeFloor}
            changeRoom={changeRoom}
            checkIn={checkIn}
          />
        }
      />
      <ModalError
        isOpen={isOpenModalError}
        message={modalErrorMessage}
        subMessage={modalErrorSubMessage}
        onClose={closeModalError}
      />
      <Spinner isLoading={isLoading} />
    </div>
  );
};

export default RoomSelectContainer;
